.FloatingIcon {
    position: fixed;
    bottom: 25px;
    right: 25px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background: url("/public/imgs/whatsapp.png");
    background-size: contain;
    z-index: 100000;
    border: 2px solid white;
}