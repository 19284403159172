.Carousel-Container {
    width: 600px;
}

.Carousel-img {
    height: 600px;
}

.Carousel-Title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 25px;
    display: none;
}

@media (max-width: 1600px) {
    .Carousel-Title {
        display: block;
    }
}

@media (max-width: 800px) {
    .Carousel-Container {
        width: 80vw;
    }

    .Carousel-img {
        height: 80vw;
    }
}