:root {
  --primary: #2f2e94;
  --white: #ffffff;
}

* {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  background-color: var(--white);
}