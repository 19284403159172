.App {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.App-body {
    display: flex;
    flex-direction: column;
    flex: 1;
}

main {
    padding: 50px 80px;
}

.flex-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}