.Navbar {
    background-color: var(--primary);
    color: var(--white);
    font-size: 24px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding-right: 20px;
}

.Navbar-logo-container {
    width: 100px;
    height: 100px;
    background: url("/public/imgs/logo.jpg");
    background-position: center;
    background-size: 120%;
    background-repeat: no-repeat;
}

.Navbar-title {
    font-family: "Nunito Sans";
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 17px;
}

.Navbar-contact {
    margin-left: auto;
    font-size: 16px;
    font-weight: 700;
}

.Navbar-contact span {
    display: inline-block;
    padding: 0 3px;
}

.Navbar-contact a,
.Navbar-contact a:visited {
    text-decoration: none;
    color: inherit;
}

.Navbar-contact a:hover {
    color: inherit;
    text-decoration: underline;
}

.Navbar-contact span:first-of-type {
    border-right: 2px solid white;
    padding-right: 10px;
    margin-right: 10px;
}

@media (max-width: 1200px) {
    .Navbar-title {
        font-size: 24px;
        letter-spacing: 12px;
    }

    .Navbar-contact {
        margin-left: auto;
        font-size: 14px;
        font-weight: 700;
    }
}

@media (max-width: 800px) {
    .Navbar-contact {
        display: none;
    }

    .Navbar {
        justify-content: center;
    }
}

@media (max-width: 500px) {
    .Navbar-title {
        font-size: 16px;
        letter-spacing: 10px;
    }

    .Navbar-logo-container {
        width: 60px;
        height: 60px;
        background: url("/public/imgs/logo.jpg");
        background-position: center;
        background-size: 120%;
        background-repeat: no-repeat;
    }
}