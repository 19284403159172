footer {
    background-color: var(--primary);
    min-height: 75px;
    height: auto;
    margin-top: auto;
    color: white;
    font-weight: 600;
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
}

.Footer-contact {
    display: none;
}

.Footer-contact a,
.Footer-contact a:visited {
    text-decoration: none;
    color: inherit;
}

.Footer-contact a:hover {
    color: inherit;
    text-decoration: underline;
}

@media (max-width: 800px) {
    .Footer-contact {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
    }
}