.Container {
    max-width: 800px;
    min-width: 800px;
}

.Container h1 {
    font-size: 36px;
    text-align: center;
    margin-bottom: 25px;
}

.Container p {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.7;
}

.Container br {
    line-height: 200px;
}

@media (max-width: 1600px) {
    .Container {
        margin-top: 60px;
    }

    .Container h1 {
        display: none;
    }
}

@media (max-width: 800px) {
    .Container p{
        width: 80vw;
        font-size: 17px;
        margin: 0 auto;
    }

    .Container p>span {
        display: inline-block;
    }
}